import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Axios from 'axios'
import VueTimeago from 'vue-timeago'
import msalAuthHandler from "vue-msal-wrapper";
import authConfig from "@/authConfig";
import { ADSAuth } from "./plugins/ads";
import VTooltip from 'v-tooltip'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.config.productionTip = false

Vue.prototype.$http = Axios
Vue.use(VueTimeago, { locale: 'en' })
Vue.use(msalAuthHandler, authConfig);
Vue.use(ADSAuth);
Vue.use(VTooltip, { defaultDelay: 300 })
Vue.use(VueToast);

new Vue({
  router,
  store,
  vuetify,  
  render: h => h(App),
}).$mount('#app')
