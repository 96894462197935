<template lang="html">
  <WelcomeView v-if="!$store.getters.isAuthenticated"/>
  <v-container v-else class="home pa-6">
    <job-number-search
            ref="input-field"
            @jobObjectSelected="selectedJobNumber"
          ></job-number-search>    
  </v-container>
</template>

<script>
import JobNumberSearch from "@/components/JobNumberSearch";
import WelcomeView from "@/views/WelcomeView";

export default {
  name: "HomeView",
  components: {
    WelcomeView,
    JobNumberSearch,
  },
  data: () => {
    return {
      loading: false,
      serverUrl: process.env.VUE_APP_SERVER_URL,
      jobObject: null,
      projectName: null,
    };
  },
  methods: {
    selectedJobNumber(event) {
      this.jobObject = event;
      if (this.jobObject) {
        this.projectName = this.jobObject.JobNameLong;
      }
      this.$emit("jobObjectSelected", event);
      this.$store.dispatch('getStreams', null)
      this.$router.push({ name: 'ProjectView', params: { jobnumber: event.JobCode } })
    },
  },
  computed: {},
};
</script>

<style lang="scss">
#viewer {
  min-height: 500px;
}

.v-data-footer__select {
  display: none !important;
}
</style>