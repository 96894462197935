<template>
  <div>
    <v-card class="mx-auto my-5" max-width="100%">
      <v-card-text>
        <p class="text-h4 text--primary">Streams</p>
      </v-card-text>
      <v-col v-if="selectedProjectStreams.length > 0" cols="12">
        <v-row :class="`${$vuetify.breakpoint.xsOnly ? '' : 'px-2'}`">
          <v-col
            v-for="(stream, i) in selectedProjectStreams"
            :key="i"
            cols="12"
            sm="6"
            md="6"
            lg="4"
            xl="4"
          >
            <list-item-stream :stream="stream"></list-item-stream>
          </v-col>
          <div class="d-flex justify-center m-auto">
            <!-- <infinite-loading
              v-if="streams.items.length < streams.totalCount"
              @infinite="infiniteHandler"
            >
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading> -->
          </div>
        </v-row>
      </v-col>
      <v-card-actions>
        <v-btn text color="primary accent-4" @click="newStreamDialog = true">
          <v-icon small color="darken-2"> mdi-plus </v-icon> Create New Stream
          for Project
        </v-btn>
        <v-btn text color="primary" @click="searchStreamDialog = true">
          <v-icon small color="darken-2"> mdi-plus </v-icon> Add Existing Stream
          to Project
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="newStreamDialog"
      max-width="500"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <stream-new-dialog
        :open="newStreamDialog"
        @created="newStreamDialog = false"
        @close="newStreamDialog = false"
        @newStream="addStream"
      />
    </v-dialog>
    <v-dialog
      v-model="searchStreamDialog"
      max-width="500"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <stream-search-dialog
        :open="searchStreamDialog"
        @created="searchStreamDialog = false"
        @close="searchStreamDialog = false"
        @newStream="addStream"
      />
    </v-dialog>
  </div>
</template>

<script>
import ListItemStream from "@/components/ListItemStream";
import StreamNewDialog from "@/components/dialogs/StreamNewDialog";
import StreamSearchDialog from "@/components/dialogs/StreamSearchDialog";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "ProjectStreamsCard",
  components: {
    ListItemStream,
    StreamNewDialog,
    StreamSearchDialog,
    //InfiniteLoading,
  },
  data() {
    return {
      newStreamDialog: false,
      searchStreamDialog: false,
      streams: [],
      selectedProjectStreams: [],
    };
  },
  props: {
    project: {
      type: Object,
    },
    OpenNewStream: {
      type: Number,
      default: 0,
    },
    OpenSearchStream: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    project: {
      async handler(newVal, oldVal) {
        this.selectedProjectStreams = await this.$store.dispatch("getProjectStreams");
      },
      deep: true,
    },
    OpenNewStream(val, old) {
      this.newStreamDialog = true;
    },
    OpenSearchStream(val, old) {
      this.searchStreamDialog = true;
    },
  },
  async created() {
    this.streams = await this.$store.dispatch("getStreams", null);
    this.selectedProjectStreams = await this.$store.dispatch("getProjectStreams");
  },
  methods: {
    async infiniteHandler($state) {
      this.loading = true;

      var cursor = this.streams.cursor;
      let moreStreams = await this.$store.dispatch("getStreams", cursor);
      this.$store.commit("addCursorToPreviousList", cursor);
      let moreProjectStreams = await this.$store.dispatch("getProjectStreams");
      console.log(moreProjectStreams)

      //set vue-infinite state
      if (moreStreams.cursor) {
        this.streams.items.push(...moreStreams.items);
        this.streams.cursor = moreStreams.cursor;
        this.streams.totalCount = moreStreams.totalCount;
        $state.loaded();
      } else {
        $state.complete();
      }

      this.loading = false;
    },
    jobNumberGlobalFilter(stream) {
      if (stream.jobNumber) {
        return stream.jobNumber;
      } else return false;
    },
    matchingJobNumberFilter(globalsJobNumber, adsJobNumber) {
      let jobNumber = globalsJobNumber.toString().trim().replace("-", "");
      return jobNumber == adsJobNumber;
    },
    async addStream(item) {
      let existingIndex = this.selectedProjectStreams.findIndex((x) => x.id === item.id);
      if (existingIndex !== -1) {
        this.selectedProjectStreams.splice(existingIndex, 1);
      } else{
        this.selectedProjectStreams.totalCount += 1;
      }
      this.selectedProjectStreams.unshift(item);

      this.streams = await this.$store.dispatch("getStreams", null);
      this.selectedProjectStreams = await this.$store.dispatch("getProjectStreams");
      await this.$store.dispatch("getProjectCollaborators");
    },
  },
  computed: {
    selectedProject: function () {
      return this.$store.state.currentProject;
    },
    previousCursors: function () {
      return this.$store.state.previousCursors || [null];
    },
  },
};
</script>
