<template>
  <v-card class="mx-auto my-5" max-width="100%">
    <v-card-text>
      <p class="text-h4 text--primary">{{ project.JobNameLong }}</p>
      <div>
        <p>
          PD: <span>{{ project.Project.ProjectManagerName }}</span> PM:
          <span>{{ project.Project.ProjectDirectorName }}</span>
        </p>
      </div>
      <div class="text--primary">
        <p>{{ project.Project.ScopeOfService }}</p>
        <p class="mb-0">{{ project.Project.ScopeOfWorks }}</p>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="primary"
        :href="project.Project.ProjectUrl"
        target="_blank"
      >
        Learn More
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ProjectInfoCard",
  data() {
    return {};
  },
  props: ["project"],
  methods: {},
  computed: {
    selectedProject: function () {
      return this.$store.state.currentProject;
    },
  },
};
</script>
