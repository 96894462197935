<template>
  <div>
    <v-card class="mx-auto my-5" max-width="100%">
      <v-card-text>
        <p class="text-h4 text--primary">Team</p>
      </v-card-text>
      <v-row align="stretch" class="px-5">
        <v-col cols="12">
          <section-card :elevation="4">
            <v-progress-linear v-show="loading" indeterminate></v-progress-linear>
            <template slot="header">
              <v-icon small class="mr-2 mb-1">mdi-account-plus</v-icon>
              <span class="d-inline-block">Invite team member</span>
            </template>
            <v-card-text>
              <v-text-field
                v-model="search"
                label="Search for a user"
                clearable
                persistent-hint
                @keyup="debounceInput(search)"
                ref="input-field"
              />
              <div class="caption">You can search by name or email.</div>
              <div v-if="loading">Searching.</div>
              <v-list
                v-if="search && search.length >= 3 && userSearch && userSearch.items"
                rounded
                dense
                one-line
                class="px-0 mx-0 transparent"
              >
                <v-list-item v-if="filteredSearchResults.length === 0" class="px-0 mx-0">
                  <v-list-item-content>
                    <v-list-item-title>No users found.</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-for="item in filteredSearchResults"
                  v-else
                  :key="item.id"
                  @click="addCollab(item)"
                >
                  <v-list-item-avatar>
                    <user-avatar
                      :id="item.id"
                      :name="item.name"
                      :avatar="item.avatar"
                      :size="25"
                      class="ml-1"
                    ></user-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.company ? item.company : 'no company info' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn color="primary">Invite</v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </section-card>
        </v-col>
        <v-col v-for="role in roles" :key="role.name" cols="12" md="4">
          <section-card expandable>
            <template slot="header">
              <span class="text-capitalize">{{ getRoleTitle(role.name) }}</span>
            </template>
            <template slot="actions">
              <v-spacer></v-spacer>
              <v-badge
                inline
                :content="getRoleCount(role.name)"
                :color="`grey ${$vuetify.theme.dark ? 'darken-1' : 'lighten-1'}`"
              ></v-badge>
            </template>
            <v-card-text class="flex-grow-1" style="height: 100px">
              {{ role.description }}
            </v-card-text>
            <v-card-text v-if="role.name === 'stream:reviewer'">
              <div v-for="user in reviewers" :key="user.id" class="d-flex align-center mb-2">
                <user-role
                  :user="user"
                  :roles="roles"
                  :disabled="false"
                  @update-user-role="(e) => setUserPermissions(e)"
                  @remove-user="removeUser"
                />
              </div>
            </v-card-text>
            <v-card-text v-if="role.name === 'stream:contributor'">
              <div v-for="user in contributors" :key="user.id" class="d-flex align-center mb-2">
                <user-role
                  :user="user"
                  :roles="roles"
                  :disabled="false"
                  @update-user-role="(e) => setUserPermissions(e)"
                  @remove-user="removeUser"
                />
              </div>
            </v-card-text>
            <v-card-text v-if="role.name === 'stream:owner'">
              <div v-for="user in owners" :key="user.id" class="d-flex align-center mb-2">
                <user-role
                  :user="user"
                  :roles="roles"
                  :disabled="false"
                  @update-user-role="(e) => setUserPermissions(e)"
                  @remove-user="removeUser"
                />
              </div>
            </v-card-text>
          </section-card>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn text color="primary accent-4" @click="reapplyStreamPermissions">
          <v-icon small color="darken-2"> mdi-plus </v-icon>Apply permissions to all project streams
        </v-btn>
      </v-card-actions>      
    </v-card>
  </div>
</template>

<script>
import UserAvatar from "@/components/UserAvatar";
import UserRole from "@/components/UserRole";
import { searchUsers, createStreamInvite, grantStreamPermissions, revokeStreamPermissions } from "@/speckleUtils";
import { debounce } from "debounce";
import SectionCard from "@/components/SectionCard";

export default {
  name: "ProjectTeamCard",
  components: {
    UserAvatar,
    SectionCard,
    UserRole
  },
  data() {
    return {
      newStreamDialog: false,
      searchStreamDialog: false,
      streams: [],
      serverInfo: { roles: [] },
      search: '',
      selectedUsers: null,
      selectedRole: null,
      userSearch: { items: [] },      
      loading: false,
    };
  },
  props: {
    project: {
      type: Object,
    },
  },
  watch: {
    project: {
      async handler(newVal, oldVal) {
        await this.$store.dispatch("getProjectCollaborators");   
      },
      deep: true,
    },
  },
  async created() {
    await this.$store.dispatch("getProjectCollaborators");
  },
  methods: {
    async fetchSearchResults(e) {
      if (!e || e?.length < 3) return;
      var json = await searchUsers(e);
      console.log(json)
      this.userSearch = json.data.userSearch;
    },   
    debounceInput: debounce(function (e) {
      this.fetchSearchResults(e);
    }, 300),     
    getRoleCount(role) {
      if (role === 'stream:owner') return this.owners.length || '0'
      if (role === 'stream:contributor') return this.contributors.length || '0'
      if (role === 'stream:reviewer') return this.reviewers.length || '0'
    }, 
    getRoleTitle(role){
      if (role === 'stream:owner') return role.split(':')[1] + "s" 
      if (role === 'stream:contributor') return role.split(':')[1] + "s" + " (write access)"
      if (role === 'stream:reviewer') return role.split(':')[1] + "s" + " (read-only access)"
    },        
    async setUserPermissions(user) {
      this.loading = true

      this.selectedProjectStreams.forEach(async(s) => {
        let streamId = s.id
        let streamUpdatePermissionsInput = {
                      streamId: streamId,
                      userId: user.id,
                      role: user.role
                    }
        await grantStreamPermissions(streamUpdatePermissionsInput)
      })

      await this.$store.dispatch("getProjectCollaborators");

      this.loading = false     
    },    
    async removeUser(user) {
      this.loading = true
      
      if (user.id !== this.myId) {
        this.selectedProjectStreams.forEach(async(s) => {
          let streamId = s.id
          let streamRevokePermissionInput = {
                        streamId: streamId,
                        userId: user.id
                      }
          let k = await revokeStreamPermissions(streamRevokePermissionInput)
          console.log(k)
        })

        await this.$store.dispatch("getProjectCollaborators");
      }
      
      this.loading = false
    },     
    async addCollab(user) {
      console.log(user)
      console.log(this.selectedProjectStreams)

      this.loading = true
      this.search = null
      this.userSearch.items = null

      await this.$store.dispatch("getProjectStreams");
      if(!this.selectedProjectStreams) this.selectedProjectStreams = await this.$store.dispatch("getProjectStreams");
      user.role = 'stream:contributor'

      this.selectedProjectStreams.forEach(async(s) => {
        let streamId = s.id

        let createStreamInviteInput = {
                      streamId: streamId,
                      userId: user.id,
                      email: user.email,
                      message: 'my message'
                    }
        let invite = await createStreamInvite(createStreamInviteInput)
        // if(invite.data && invite.data.streamInviteCreate){
        //   let streamUpdatePermissionsInput = {
        //                 streamId: streamId,
        //                 userId: user.id,
        //                 role: user.role
        //               }
        //   let grant = await grantStreamPermissions(streamUpdatePermissionsInput)
        // }
      })
      this.$toast.open({
          message: 'Great! An invite has been sent.',
          type: 'success',
          position: 'bottom'
      });

      await this.$store.dispatch("getProjectCollaborators");
      this.loading = false      
    },    
    async reapplyStreamPermissions(){
      this.loading = true
      this.selectedProjectCollaborators.forEach(async(u) => {
        this.selectedProjectStreams.forEach(async(s) => {
          let streamId = s.id
          let streamUpdatePermissionsInput = {
                        streamId: streamId,
                        userId: u.id,
                        role: u.role
                      }
          await grantStreamPermissions(streamUpdatePermissionsInput)
        })
      })

      await this.$store.dispatch("getProjectCollaborators");
      this.loading = false 
    },             
  },
  computed: {
    selectedProject: function () {
      return this.$store.state.currentProject;
    },
    selectedProjectStreams: function () {
      return this.$store.state.currentProjectStreams;
    },
    selectedProjectCollaborators: function () {
      return this.$store.state.currentProjectCollaborators;
    },
    roles() {
      // return this.$store.state.serverInfo.roles.filter((x) => x.resourceTarget === 'streams').filter((u) => u.name !== 'stream:owner')
      return this.$store.state.serverInfo.roles.filter((x) => x.resourceTarget === 'streams')
    }, 
    filteredSearchResults() {
      if (!this.userSearch) return null
      let users = []
      for (let u of this.userSearch.items) {
        if (u.id === this.myId) continue
        let indx = this.collaborators.findIndex((eu) => eu.id === u.id)
        if (indx === -1) users.push(u)
      }
      return users
    },    
    myId() {
      return localStorage.getItem('uuid')
    },
    collaborators() {
      return this.$store.state.currentProjectCollaborators.filter((user) => user.id !== this.myId)
    },
    reviewers() {
      return this.$store.state.currentProjectCollaborators.filter((u) => u.role === 'stream:reviewer')
    },
    owners() {
      return this.$store.state.currentProjectCollaborators.filter((u) => u.role === 'stream:owner')
    },     
    contributors() {
      return this.$store.state.currentProjectCollaborators.filter((u) => u.role === 'stream:contributor')
    },              
  },
};
</script>
