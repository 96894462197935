<template>
  <v-card>
    <v-toolbar color="primary" dark flat>
      <v-app-bar-nav-icon style="pointer-events: none">
        <v-icon>mdi-plus-box</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Add Existing Stream to Project</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="clearAndClose"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="px-2"
      @submit.prevent="addStream"
    >
      <v-card-text>
        <v-spacer></v-spacer>
        <stream-search @selected="handleSelection($event)" />
        <span v-if="selectedStream">
          <div class="d-flex justify-center my-2">
            {{ selectedStream.name }} — {{ selectedStream.id }}
          </div>
          <div class="d-flex justify-center my-4">
            <v-btn
              outlined
              text
              small
              class="ml-3"
              :href="serverUrl + '/streams/' + selectedStream.id"
              >View in server</v-btn
            >
            <v-btn
              outlined
              text
              small
              class="ml-3"
              color="error"
              @click="$store.dispatch('clearStreamSelection')"
              >Clear selection</v-btn
            >
          </div>
          <!-- <div>
            <v-autocomplete            
              v-for="item in getDefaultGlobalsInputs()"
              v-model="selected[getModel(item[0])]"
              :key="item[0]"
              :label="`${item[0]} (optional)`"
              :placeholder="item[1]"
              :items="getItems(item[0])"
              @change="updateItems(item[0], $event)"
              clearable
              multiple
              chips
            >
            </v-autocomplete>
            <v-text-field
              v-for="(item, index) in getUserGlobalsInputs()"
              :key="index"
              v-model="userGlobalsValues[index]"
              :label="item[0]"
              :placeholder="item[1]"
            ></v-text-field>
          </div> -->
          <v-text-field
            :value="getJobCodesByName()"
            label="Job Name"
            placeholder="Job Name"
            disabled
          />
          <v-text-field
            :value="getJobCode()"
            label="Job Number"
            placeholder="Job Number"
            disabled
          />
          <!-- <v-switch
            v-model="isPublic"
            v-tooltip="
              isPublic
                ? `Anyone can view this stream. It is also visible on your profile page. Only collaborators
          can edit it.`
                : `Only collaborators can access this stream.`
            "
            inset
            :label="`${isPublic ? 'Public stream' : 'Private stream'}`"
          /> -->
        </span>
        <span v-else>
          <div class="d-flex justify-center my-2">
            No stream selected. Find one using the search bar 👆🏼
          </div>
        </span>
      </v-card-text>
      <v-card-actions class="pb-3">
        <v-btn
          color="primary"
          block
          large
          :disabled="!valid"
          :loading="isLoading"
          elevation="0"
          type="submit"
        >
          Add Stream to Project
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>

import StreamSearch from "@/components/StreamSearch";
import {
  createGlobalsObject,
  createGlobalsCommit,
  searchStreams,
  bulkGrantStreamPermissions
} from "@/speckleUtils";

const { capture } = require("../../plugins/logging.js");

export default {
  components: { StreamSearch },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    redirect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      name: null,
      description: null,
      isPublic: false,
      nameRules: [],
      isLoading: false,
      valid: false,
      matchingLocations: [],
      defaultGlobalsJobs: [
        "JobNumber",
        "JobName",
        "Project Number",
        "Project Name",
      ],
      defaultGlobalsOther: ["Discipline", "Office", "Region"],
      userGlobals: [],
      userGlobalsValues: [],
      items: {
        office: [],
        region: [],
        discipline: [],
      },
      selected: {
        office: [],
        region: [],
        discipline: [],
      },
      serverUrl: process.env.VUE_APP_SERVER_URL.trim(),
    };
  },
  computed: {
    selectedStream: function () {
      return this.$store.state.currentStream;
    },
    selectedStreamGlobals: function () {
      return this.$store.state.currentStreamGlobals;
    },
    defaultGlobals: function () {
      return this.$store.state.serverInfo.defaultGlobals;
    },
    currentProject: function () {
      return this.$store.state.currentProject;
    },
    locationLookupItems: function () {
      return this.$store.state.locations;
    },
  },
  watch: {
    open: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.description = null;
          this.name = null;
          this.selected.office = [];
          this.selected.region = [];
          this.selected.discipline = [];
          this.isPublic = false;
          this.$store.dispatch("clearStreamSelection");

          if (this.userSearch) this.userSearch.items = null;
          this.collabs = [];
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.nameRules = [
      (v) =>
        !v ||
        (v.length <= 150 && v.length >= 3) ||
        "Stream name must be between 3 and 150 characters.",
    ];
    this.items.office = this.$store.state.offices;
    this.items.discipline = this.$store.state.disciplines;
    this.items.region = this.$store.state.regions;    
  },
  methods: {
    clearAndClose() {
      this.description = null;
      this.name = null;
      this.selected.office = [];
      this.selected.region = [];
      this.selected.discipline = [];
      this.isPublic = false;
      this.$store.dispatch("clearStreamSelection");
      this.$emit("close");
    },
    getItems(key) {
      switch (key) {
        case "Discipline":
          return this.items.discipline;
        case "Office":
          return this.items.office;
        case "Region":
          return this.items.region;
        default:
      }
    },
    updateItems(key, value) {
      if (value) {
        if (value.length > 0) {
          switch (key) {
            case "Office":
              this.matchingLocations = this.locationLookupItems.filter((i) =>
                value.includes(i.City)
              );
              this.selected.region = this.matchingLocations.map(
                (l) => l.RegionName
              );
              break;
            case "Region":
              this.matchingLocations = this.locationLookupItems.filter((i) =>
                value.includes(i.RegionName)
              );
              this.items.office = this.matchingLocations.map((l) => l.City);
              break;
            default:
          }
        } else {
          this.items.office = this.$store.state.offices;
          this.items.discipline = this.$store.state.disciplines;
        }
      }
    },
    getModel(key) {
      switch (key) {
        case "Discipline":
          return "discipline";
        case "Office":
          return "office";
        case "Region":
          return "region";
        default:
      }
    },
    async handleSelection(val) {
      await this.$store.dispatch("handleStreamSelection", val);

      this.name = this.selectedStream.name;
      this.description = this.selectedStream.description;
      this.isPublic = this.selectedStream.isPublic;

      if (this.selectedStreamGlobals) {
        if (
          this.selectedStreamGlobals.items.Office &&
          this.selectedStreamGlobals.items.Region
        ) {
          let office = this.selectedStreamGlobals.items.Office.split(",");
          let region = this.selectedStreamGlobals.items.Region.split(",");
          this.selected.office = office;
          this.selected.region = region;
          this.matchingLocations = this.locationLookupItems.filter((i) =>
            region.includes(i.RegionName)
          );
          this.items.office = this.matchingLocations.map((l) => l.City);
        } else if (this.selectedStreamGlobals.items.Discipline) {
          this.selected.discipline =
            this.selectedStreamGlobals.items.Discipline.split(",");
        }
      }
    },
    getDefaultGlobalsInputs() {
      let defaultInputGlobals = Object.fromEntries(
        Object.entries(this.defaultGlobals).filter(
          ([key]) =>
            key != "JobNumber" &&
            key != "JobName" &&
            this.defaultGlobalsOther.indexOf(key) !== -1
        )
      );
      var data = Object.entries(defaultInputGlobals);
      return data;
    },
    getUserGlobalsInputs() {
      if (this.selectedStreamGlobals) {
        if (this.selectedStreamGlobals.items) {
          let disabledGlobals = this.defaultGlobalsJobs.concat(
            this.defaultGlobalsOther
          );
          let userGlobalsPairs = Object.fromEntries(
            Object.entries(this.selectedStreamGlobals.items).filter(
              ([key]) => disabledGlobals.indexOf(key) === -1
            )
          );
          this.userGlobals = Object.keys(userGlobalsPairs);
          return Object.entries(userGlobalsPairs);
        } else {
          return {};
        }
      }
    },
    getJobCodesByName() {
      return this.currentProject.JobNameLong;
    },
    getJobCode() {
      return this.currentProject.JobCode;
    },
    async addStream() {
      if (!this.$refs.form.validate()) return;

      this.isLoading = true;

      try {
        let streamUpdateInput = {
          id: this.selectedStream.id,
          jobNumber: this.currentProject.JobCode
        };

        let streamUpdated = await this.$store.dispatch(
          "updateStreamJobNumber",
          streamUpdateInput
        );

        if(streamUpdated){
          await bulkGrantStreamPermissions(this.selectedStream.id)

          var json = await searchStreams(this.selectedStream.id);
          let item = json.data.streams.items[0];

          this.$emit("newStream", item);

          capture("stream_updated_in_project_app", {
            streamId: this.selectedStream.id,
            jobNumber: this.currentProject.JobCode,
            user: this.$store.state.user,
            app: "project-app",
          });
        } else {
          throw 'Failed to update stream job number'
        }        
        this.clearAndClose()       
      } catch (e) {
        console.log(e);
        this.clearAndClose() 
      }
      this.isLoading = false;
    },
    async createGlobals(streamId) {
      try {
        let globals = {
          Office: this.selected.office.toString(),
          Region: this.selected.region.toString(),
          Discipline: this.selected.discipline.toString(),
          JobName: this.currentProject.JobNameLong,
          JobNumber: this.currentProject.JobCode,
          speckle_type: "Base",
        };

        if (this.userGlobals.length > 0) {
          this.userGlobals.forEach((element, i) => {
            let key = element.replace(/ /g, "_");
            globals[key] = this.userGlobalsValues[i];
          });
        }
        let objId = await createGlobalsObject(streamId, globals);
        return objId;
      } catch (e) {
        console.log(e);
      }
    },
    async createCommit(streamId, objectId) {
      try {
        let commitId = await createGlobalsCommit(streamId, objectId);
        return commitId;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
