<template>
  <v-app>
    <v-app-bar app color="primary" :dark="$vuetify.theme.dark">
      <div class="d-flex align-center">
        <v-img
          alt="Speckle@Arup Logo"
          class="shrink mr-2"
          contain
          :src="require(`@/assets/img.png`)"
          transition="scale-transition"
          width="40"
          height="24"
        />
        <a href="/" style="text-decoration:none;color:white;">Speckle@Arup Project Setup App (beta)</a>
      </div>
      <v-spacer></v-spacer>
      <div v-if="isAuthenticated" class="mx-4">
        Welcome <b>{{ this.$store.state.user.name }}</b
        >!
      </div>
      <v-btn
        outlined
        class="mx-2"
        v-if="!isAuthenticated"
        @click="$store.dispatch('redirectToAuth')"
      >
        <span>Login with Speckle</span>
      </v-btn>
      <v-btn outlined v-else @click="$store.dispatch('logout')">
        Log out
      </v-btn>
      <v-btn outlined class="mx-2" @click="switchTheme">
        <v-icon small class="ml-1">mdi-theme-light-dark</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <msal-wrapper>
        <router-view />
      </msal-wrapper>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  methods: {
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
        "darkModeEnabled",
        this.$vuetify.theme.dark ? "dark" : "light"
      );
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    background() {
      let theme = this.$vuetify.theme.dark ? 'dark' : 'light'
      return `background-color: ${this.$vuetify.theme.themes[theme].background};`
    },    
  },
};
</script>