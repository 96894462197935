export default {
  msalConfig: {
    auth: {
      clientId: process.env.VUE_APP_ADS_CLIENT_ID,
      authority: process.env.VUE_APP_ADS_AUTHORITY_URL,
      redirectUri: window.location.origin + "/login/aad",
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  },
  tokenTypes: {
    login: {
      scopes: ["User.Read"],
      extraScopesToConsent: [
        "User.ReadBasic.All",
        process.env.VUE_APP_ADS_RESOURCE + "/user_impersonation",
      ],
    },
    graph: {
      scopes: ["User.Read"],
    },
    ads: {
      scopes: [process.env.VUE_APP_ADS_RESOURCE + "/user_impersonation"],
    },
  },
};