<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :href="serverUrl+'/streams/'+ stream.id"
      target="_blank"
      color=""
      :elevation="hover ? 5 : 1"
      style="transition: all 0.2s ease-in-out"
    >
      <v-toolbar class="transparent elevation-0" dense>
        <v-toolbar-title>{{ stream.name }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text class="transparent elevation-0 mt-0 pt-0" dense>
        <v-toolbar-title>
          <v-chip small class="mr-1">
            Updated
            <timeago :datetime="stream.updatedAt" class="ml-1"></timeago>
          </v-chip>
        </v-toolbar-title>
        <div class="mt-3 mb-1 caption text-truncate">
          {{ stream.description || "No description" }}
        </div>
        <div v-if="stream.collaborators">
        </div>
      </v-card-text>
    </v-card>
  </v-hover>
</template>
<script>

export default {
  components: {},
  data: () => {
    return {
      serverUrl: process.env.VUE_APP_SERVER_URL,
    };
  },
  props: {
    stream: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    branchesTotalCount() {
      if (!this.stream.branches) return 0;
      return this.stream.branches.items.filter((b) => b.name !== "globals")
        .length;
    },
  },
};
</script>
