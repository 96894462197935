export const userAndServerInfoQuery = () => `query {
      user {
        id
        name
      },
      serverInfo {
        name
        company
        defaultGlobals
        roles {
          name
          description
          resourceTarget
        }
      }
    }`


export const userByIdQuery = (userId) => `query {
    user(id: "${userId}") {
      id
      name
      bio
      company
      avatar
      verified
      profiles
      role
      suuid
    }
  }`


export const userSearchQuery = (query) => `query {
  userSearch(query: "${query}", limit: 25) {
    cursor
    items {
      id
      name
      bio
      company
      avatar
      verified
    }
  }
}`

export const streamCommitsQuery = (streamId, itemsPerPage, cursor) => `query {
    stream(id: "${streamId}"){
      commits(limit: ${itemsPerPage}, cursor: ${cursor ? '"' + cursor + '"' : null}) {
        totalCount
        cursor
        items{
          id
          message
          branchName
          sourceApplication
          referencedObject
          authorName
          createdAt
        }
      }
    }
  }`

export const streamSearchQuery = (search) => `query {
      streams(query: "${search}") {
        totalCount
        cursor
        items {
          id
          name
          jobNumber
          description
          updatedAt
          role
          isPublic
          globals {
            items
          }          
        }
      }
    }`

export const streamsQuery = (cursor) => `query {
  streams(cursor: ${cursor ? '"' + cursor + '"' : null}) {
    totalCount
    cursor
    items {
      id
      name
      jobNumber
      description
      updatedAt
      isPublic
      globals {
        items
      }
    }
  }
}`

export const streamGlobalsQuery = (streamId) => `query {
  stream(id: "${streamId}"){
    globals{
      items
    }
  }
}`

export const streamGlobalsBranchQuery = (streamId) => `query {
  stream(id: "${streamId}"){
    branch(name: "globals"){
      id
    }
  }
}`

export const streamCollaboratorsQuery = (streamId) => `query {
  stream(id: "${streamId}"){
    collaborators{
      id
      name
      role
      company
      avatar
    }
  }
}`

export const createStreamMutation = (streamCreateInput) => `mutation {
  streamCreate(stream: {
    name: "${streamCreateInput.name}",
    description: "${streamCreateInput.description}",
    isPublic: ${streamCreateInput.isPublic},
    jobNumber: "${streamCreateInput.jobNumber}"
  })
}`

export const createStreamInviteMutation = (createStreamInviteInput) => `mutation {
  streamInviteCreate(input: {
    email: "${createStreamInviteInput.email}",
    message: "${createStreamInviteInput.message}",
    streamId: "${createStreamInviteInput.streamId}",
    userId: "${createStreamInviteInput.userId}"
  })
}`

export const updatePermissionsMutation = (streamUpdatePermissionsInput) => `mutation {
  streamUpdatePermission(permissionParams: {
    streamId: "${streamUpdatePermissionsInput.streamId}",
    userId: "${streamUpdatePermissionsInput.userId}",
    role: "${streamUpdatePermissionsInput.role}"
  })
}`

export const revokePermissionsMutation = (streamRevokePermissionInput) => `mutation {
  streamRevokePermission(permissionParams: {
    streamId: "${streamRevokePermissionInput.streamId}",
    userId: "${streamRevokePermissionInput.userId}"
  })
}`

export const createObjectMutation = (objectsInput) => `mutation {
  objectCreate(objectInput: {
    streamId: "${objectsInput.streamId}",
    objects: ${objectsInput.objects}
  })
}`

export const createCommitMutation = (commitInput) => `mutation {
  commitCreate(commit: {
    streamId: "${commitInput.streamId}",
    branchName: "${commitInput.branchName}",
    objectId: "${commitInput.objectId}",
    message: "${commitInput.message}",
    sourceApplication: "${commitInput.sourceApplication}"
  })
}`

export const updateStreamMutation = (streamUpdateInput) => `mutation {
  streamUpdate(stream: {
    id: "${streamUpdateInput.id}",
    name: "${streamUpdateInput.name}",
    description: "${streamUpdateInput.description}",
    isPublic: ${streamUpdateInput.isPublic},
    jobNumber: "${streamUpdateInput.jobNumber}"
  })
}`

export const updateStreamJobNumberMutation = (streamUpdateInput) => `mutation {
  streamUpdate(stream: {
    id: "${streamUpdateInput.id}",
    jobNumber: "${streamUpdateInput.jobNumber}"
  })
}`

export const createBranchMutation = (branchCreateInput) => `mutation {
  branchCreate(branch: {
    streamId: "${branchCreateInput.streamId}",
    name: "${branchCreateInput.name}"
  })
}`


