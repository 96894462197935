<template>
  <v-card>
    <v-toolbar color="primary" dark flat>
      <v-app-bar-nav-icon style="pointer-events: none">
        <v-icon>mdi-plus-box</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Create a New Stream</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="clearAndClose"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="px-2"
      @submit.prevent="createStream"
    >
      <v-card-text>
        <v-text-field
          v-model="name"
          :rules="nameRules"
          validate-on-blur
          autofocus
          label="Stream Name (optional)"
        />
        <v-textarea
          v-model="description"
          rows="1"
          row-height="15"
          label="Description (optional)"
        />
        <!-- <v-autocomplete
          v-for="(item, index) in globalsInputs"
          v-model="selected[getModel(item[0])]"
          :key="index"
          :label="`${item[0]} (optional)`"
          :placeholder="item[1]"
          :items="getItems(item[0])"
          @change="updateItems(item[0], $event)"
          clearable
          multiple
          chips
        >
        </v-autocomplete> -->
        <v-text-field
          :value="selectedProject.JobNameLong"
          label="Job Name"
          placeholder="Job Name"
          disabled
        />
        <v-text-field
          :value="selectedProject.JobCode"
          label="Job Number"
          placeholder="Job Number"
          disabled
        />
        <v-switch
          v-model="isPublic"
          v-tooltip="
            isPublic
              ? `Anyone can view this stream. It is also visible on your profile page. Only collaborators
          can edit it.`
              : `Only collaborators can access this stream.`
          "
          inset
          :label="`${isPublic ? 'Public stream' : 'Private stream'}`"
        />
        <!-- <p class="mt-5">
          <b>Share this stream with your colleagues now! 🤙</b>
        </p>
        <v-text-field
          v-model="search"
          label="Search users..."
          placeholder="Search by name or by email"
        />
        <v-chip
          v-for="user in collabs"
          :key="user.id"
          close
          class="ma-2"
          @click:close="removeCollab(user)"
        >
          <user-avatar
            :id="user.id"
            :name="user.name"
            :avatar="user.avatar"
            :size="25"
            left
          ></user-avatar>
          <span class="caption">{{ user.name }}</span>
        </v-chip> -->
      </v-card-text>
      <v-card-actions class="pb-3">
        <v-btn
          color="primary"
          block
          large
          :disabled="!valid"
          :loading="isLoading"
          elevation="0"
          type="submit"
        >
          Create Stream
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {
  createGlobalsObject,
  createGlobalsCommit,
  searchStreams,
  bulkGrantStreamPermissions,
} from "@/speckleUtils";

const { capture } = require("../../plugins/logging.js");

export default {
  components: {},
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    redirect: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    selectedProject: function () {
      return this.$store.state.currentProject;
    },
    globalsInputs: function () {
      let defaultInputGlobals = Object.fromEntries(
        Object.entries(this.$store.state.serverInfo.defaultGlobals).filter(
          ([key]) => key != "JobNumber" && key != "JobName"
        )
      );
      return Object.entries(defaultInputGlobals);
    },
    locationLookupItems: function () {
      return this.$store.state.locations;
    },
  },
  data() {
    return {
      name: null,
      description: null,
      valid: false,
      search: null,
      nameRules: [],
      isPublic: false,
      collabs: [],
      isLoading: false,
      matchingLocations: [],
      items: {
        office: [],
        region: [],
        discipline: [],
      },
      selected: {
        office: [],
        region: [],
        discipline: [],
      },
    };
  },
  watch: {
    open: {
      handler(newVal, oldVal) {
        this.name = null;
        this.search = null;
        if (this.userSearch) this.userSearch.items = null;
        this.collabs = [];

        this.matchingLocations = this.locationLookupItems.filter(
          (i) => i.RegionCode === this.selectedProject.RegionCode
        );

        if (this.matchingLocations) {
          if (this.matchingLocations.length > 0) {
            let initialRegion = this.matchingLocations[0].RegionName;
            this.items.office = this.matchingLocations.map((l) => l.City);
            this.selected.region = initialRegion;
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.nameRules = [
      (v) =>
        !v ||
        (v.length <= 150 && v.length >= 3) ||
        "Stream name must be between 3 and 150 characters.",
    ];

    this.items.office = this.$store.state.offices;
    this.items.discipline = this.$store.state.disciplines;
    this.items.region = this.$store.state.regions;
  },
  methods: {
    clearAndClose() {
      this.description = null;
      this.name = null;
      this.selected.office = [];
      this.selected.region = [];
      this.selected.discipline = [];
      this.isPublic = false;
      this.$emit("close");
    },
    getItems(key) {
      switch (key) {
        case "Discipline":
          return this.items.discipline;
        case "Office":
          return this.items.office;
        case "Region":
          return this.items.region;
        default:
      }
    },
    updateItems(key, value) {
      if (value) {
        if (value.length > 0) {
          switch (key) {
            case "Office":
              this.matchingLocations = this.locationLookupItems.filter((i) =>
                value.includes(i.City)
              );
              this.selected.region = this.matchingLocations.map(
                (l) => l.RegionName
              );
              break;
            case "Region":
              this.matchingLocations = this.locationLookupItems.filter((i) =>
                value.includes(i.RegionName)
              );
              this.items.office = this.matchingLocations.map((l) => l.City);
              break;
            default:
          }
        } else {
          this.items.office = this.$store.state.offices;
          this.items.discipline = this.$store.state.disciplines;
        }
      }
    },
    getModel(key) {
      switch (key) {
        case "Discipline":
          return "discipline";
        case "Office":
          return "office";
        case "Region":
          return "region";
        default:
      }
    },
    async createStream() {
      if (!this.$refs.form.validate()) return;

      this.isLoading = true;
      try {
        let streamCreateInput = {
          name: this.name || "",
          description: this.description || "",
          isPublic: this.isPublic || false,
          jobNumber: this.selectedProject.JobCode
        };

        let streamId = await this.$store.dispatch(
          "createStream",
          streamCreateInput
        );

        this.$emit("created");

        await bulkGrantStreamPermissions(streamId);

        var json = await searchStreams(streamId);
        let item = json.data.streams.items[0];

        this.$emit("newStream", item);
        capture("stream_created_in_project_app", {
          streamId: streamId,
          jobNumber: this.selectedProject.JobCode,
          user: this.$store.state.user,
          app: "project-app",
        });
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
    async createGlobals(streamId) {
      try {
        let globals = {
          Office: this.selected.office.toString(),
          Region: this.selected.region.toString(),
          Discipline: this.selected.discipline.toString(),
          JobName: this.selectedProject.JobNameLong,
          JobNumber: this.selectedProject.JobCode,
          speckle_type: "Base",
        };
        let objId = await createGlobalsObject(streamId, globals);
        return objId;
      } catch (e) {
        console.log(e);
      }
    },
    async createCommit(streamId, objectId) {
      try {
        let commitId = await createGlobalsCommit(streamId, objectId);
        return commitId;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
