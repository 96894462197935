import { userAndServerInfoQuery, userByIdQuery, userSearchQuery } from "@/speckleQueries";
import { streamCommitsQuery, streamSearchQuery, streamCollaboratorsQuery, streamsQuery, streamGlobalsQuery, streamGlobalsBranchQuery } from "@/speckleQueries";
import { createStreamMutation, createStreamInviteMutation, updatePermissionsMutation, revokePermissionsMutation, createObjectMutation, createCommitMutation, updateStreamMutation, updateStreamJobNumberMutation, createBranchMutation } from "@/speckleQueries";
import Vue from 'vue'
import Vuex from 'vuex'
import $store from '@/store/index'

export const APP_NAME = process.env.VUE_APP_SPECKLE_NAME
export const SERVER_URL = process.env.VUE_APP_SERVER_URL.trim()
export const TOKEN = `${APP_NAME}.AuthToken`
export const REFRESH_TOKEN = `${APP_NAME}.RefreshToken`
export const CHALLENGE = `${APP_NAME}.Challenge`

// Redirects to the Speckle server authentication page, using a randomly generated challenge. Challenge will be stored to compare with when exchanging the access code.
export function goToSpeckleAuthPage() {
  // Generate random challenge
  var challenge = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  // Save challenge in localStorage
  localStorage.setItem(CHALLENGE, challenge)
  // Send user to auth page
  window.location = `${SERVER_URL}/authn/verify/${process.env.VUE_APP_SPECKLE_ID}/${challenge}`
}

// Log out the current user. This removes the token/refreshToken pair.
export function speckleLogOut() {
  // Remove both token and refreshToken from localStorage
  localStorage.removeItem(TOKEN)
  localStorage.removeItem(REFRESH_TOKEN)
}

// Exchanges the provided access code with a token/refreshToken pair, and saves them to local storage.
export async function exchangeAccessCode(accessCode) {
  var res = await fetch(`${SERVER_URL}/auth/token/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessCode: accessCode,
      appId: process.env.VUE_APP_SPECKLE_ID,
      appSecret: process.env.VUE_APP_SPECKLE_SECRET,
      challenge: localStorage.getItem(CHALLENGE)
    })
  })
  var data = await res.json()
  if (data.token) {
    // If retrieving the token was successful, remove challenge and set the new token and refresh token
    localStorage.removeItem(CHALLENGE)
    localStorage.setItem(TOKEN, data.token)
    localStorage.setItem(REFRESH_TOKEN, data.refreshToken)
  }
  return data
}

// Calls the GraphQL endpoint of the Speckle server with a specific query.
export async function speckleFetch(query) {
  let token = localStorage.getItem(TOKEN)
  if (token)
    try {
      var res = await fetch(
        `${SERVER_URL}/graphql`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: query
          })
        })
      return await res.json()
    } catch (err) {
      console.error("API call failed", err)
    }
  else
    return Promise.reject("You are not logged in (token does not exist)")
}

export async function speckleFetchMore(query) {
  let token = localStorage.getItem(TOKEN)
  if (token)
    try {
      var res = await fetch(
        `${SERVER_URL}/graphql`,
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: query
          })
        })
      return await res.json()
    } catch (err) {
      console.error("API call failed", err)
    }
  else
    return Promise.reject("You are not logged in (token does not exist)")
}

export async function getLocations() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$ads
      .get("Locations", {
        params: {
          $select: "City,RegionName,RegionCode",
          $orderby: "RegionName,City",
          $filter: "LocationType eq 'Office' and Active eq 1"
        }
      })
      .then(response => {
        let payload = response.data;
        if (!payload) return reject(null);
        return resolve(payload.value);
      })
      .catch(error => {
        return reject(error);
      });
  });
}

export async function getOfficesAndRegions() {
  let locations = await getLocations()
  locations = locations.map((l) => {
    if (l.City) return l;
  });
  locations = locations.filter(Boolean);

  let offices = locations.map((l) => {
    return l.City;
  });
  offices = [...new Set(offices)];
  let regions = locations.map((l) => {
    return l.RegionName;
  });
  regions = [...new Set(regions)];

  return { offices, regions, locations }
}

export async function getDisciplines() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$ads
      .get("Disciplines", {
        params: {
          $select: "DisciplineName",
          $orderby: "DisciplineName",
          $filter: "Active eq 1"
        }
      })
      .then(response => {
        let payload = response.data;
        if (!payload) return reject(null);
        let disciplines = payload.value.map((d) => {
          if (d.DisciplineName) return d.DisciplineName;
        });
        disciplines = [...new Set(disciplines)];
        return resolve(disciplines);
      })
      .catch(error => {
        return reject(error);
      });
  });
}

export function jobNumberGlobalFilter(stream) {
  if (stream.jobNumber) {
    return stream.jobNumber;
  } else return false;
}

export function matchingJobNumberFilter(globalsJobNumber, adsJobNumber) {
  let jobNumber = globalsJobNumber.toString().trim().replace("-", "");
  return jobNumber === adsJobNumber;
}

export async function createGlobalsObject(streamId, globalsObject) {
  try {
    let globalsString = JSON.stringify(globalsObject);
    globalsString = globalsString.replace(/"([^"]+)":/g, "$1:");

    let globalsObjectInput = {
      streamId: streamId,
      objects: globalsString,
    };

    let objId = await $store.dispatch(
      "createObject",
      globalsObjectInput
    );

    return objId;
  } catch (e) {
    console.log(e);
  }
}

export async function createGlobalsCommit(streamId, objectId) {
  let json = await getStreamGlobalsBranch(streamId)

  if (!json.data.stream.branch) {
    let globalsBranchJson = await createBranch({ streamId: streamId, name: "globals" })
    if (!globalsBranchJson.data.branchCreate) return;
  }

  try {
    let commit = {
      streamId: streamId,
      branchName: "globals",
      objectId: objectId,
      message: "Add globals from project setup app",
      sourceApplication: "project-app",
    };

    let commitId = await $store.dispatch("createCommit", commit);
    return commitId;
  } catch (e) {
    console.log(e);
  }
}

export async function bulkGrantStreamPermissions(streamId) {
  try {
    let role = 'stream:contributor'

    let users = $store.state.currentProjectCollaborators;

    users.forEach(async (u) => {
      let streamUpdatePermissionsInput = {
        streamId: streamId,
        userId: u.id,
        role: role
      }
      console.log(streamUpdatePermissionsInput)
      await grantStreamPermissions(streamUpdatePermissionsInput)
    });
  } catch (e) {
    console.log(e);
  }
}

export async function bulkGrantStreamPermissionsByRole(streamId, role) {
  try {
    let users = $store.state.currentProjectCollaborators;

    users.forEach(async (u) => {
      let streamUpdatePermissionsInput = {
        streamId: streamId,
        userId: u.id,
        role: role
      }
      console.log(streamUpdatePermissionsInput)
      await grantStreamPermissions(streamUpdatePermissionsInput)
    });
  } catch (e) {
    console.log(e);
  }
}


// Fetch the current user data using the userAndServerInfoQuery
export const getUserAndServerData = () => speckleFetch(userAndServerInfoQuery())

// Fetch the user based on their id
export const getUserById = (userId) => speckleFetch(userByIdQuery(userId))

// Fetch the user based on provided query
export const searchUsers = (e) => speckleFetch(userSearchQuery(e))

// Fetch for streams matching the specified text using the streamSearchQuery
export const searchStreams = (e) => speckleFetch(streamSearchQuery(e))

// Get streams
export const getStreams = (cursor) => speckleFetch(streamsQuery(cursor))

// Get commits related to a specific stream, allows for pagination by passing a cursor
export const getStreamCommits = (streamId, itemsPerPage, cursor) => speckleFetch(streamCommitsQuery(streamId, itemsPerPage, cursor))

// Get globals related to a specific stream
export const getStreamGlobals = (e) => speckleFetch(streamGlobalsQuery(e))

// Get globals branch of a specific stream
export const getStreamGlobalsBranch = (e) => speckleFetch(streamGlobalsBranchQuery(e))

// Get stream collaborators
export const getStreamCollaborators = (e) => speckleFetch(streamCollaboratorsQuery(e))

// Create new stream
export const createStream = (streamCreateInput) => speckleFetch(createStreamMutation(streamCreateInput))

// Grant stream permissions
export const createStreamInvite = (createStreamInviteInput) => speckleFetch(createStreamInviteMutation(createStreamInviteInput))

// Grant stream permissions
export const grantStreamPermissions = (streamUpdatePermissionsInput) => speckleFetch(updatePermissionsMutation(streamUpdatePermissionsInput))

// Revoke stream permissions
export const revokeStreamPermissions = (streamRevokePermissionInput) => speckleFetch(revokePermissionsMutation(streamRevokePermissionInput))

// Create stream object 
export const createObject = (objectsInput) => speckleFetch(createObjectMutation(objectsInput))

// Create stream commit 
export const createCommit = (commitInput) => speckleFetch(createCommitMutation(commitInput))

// Update stream  
export const updateStream = (streamUpdateInput) => speckleFetch(updateStreamMutation(streamUpdateInput))

// Update stream job number  
export const updateStreamJobNumber = (streamUpdateInput) => speckleFetch(updateStreamJobNumberMutation(streamUpdateInput))

// Create branch  
export const createBranch = (branchCreateInput) => speckleFetch(createBranchMutation(branchCreateInput))