import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import {
  APP_NAME,
  exchangeAccessCode,
  getStreamCommits,
  getStreams,
  getStreamGlobals,
  getStreamCollaborators,
  createStream,
  createObject,
  createCommit,
  updateStream,
  updateStreamJobNumber,
  getUserAndServerData,
  getUserById,
  getOfficesAndRegions,
  getDisciplines,
  goToSpeckleAuthPage,
  speckleLogOut,
  jobNumberGlobalFilter,
  matchingJobNumberFilter
} from "@/speckleUtils";

const { identify } = require('../plugins/logging.js')

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: `${process.env.VUE_APP_SPECKLE_NAME}.vuex`
})

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    user: null,
    serverInfo: null,
    currentStream: null,
    currentStreamGlobals: null,
    streams: [null],
    previousCursors: [null],
    token: null,
    isAuth: false,
    useCache: false,
    currentProject: null,
    currentProjectStreams: [null],
    currentProjectCollaborators: [null],
    disciplines: [null],
    offices: [null],
    regions: [null],
    locations: [null]
  },
  getters: {
    isAuthenticated: (state) => state.user != null
  },
  mutations: {
    setUser(state, user) {
      if (state.user !== null && user !== null) {
        if (state.user !== user) identify(user)
      }      
      state.user = user      
    },
    setServerInfo(state, info) {
      state.serverInfo = info
    },
    setCurrentStream(state, stream) {
      state.currentStream = stream
    },
    setCurrentStreamGlobals(state, globals) {
      state.currentStreamGlobals = globals
    },
    setStreams(state, streams) {
      state.streams = streams
    },
    setProjectStreams(state, streams) {
      state.currentProjectStreams = streams
    },  
    setProjectCollaborators(state, collaborators) {
      state.currentProjectCollaborators = collaborators
    },  
    resetPrevCursors(state) {
      state.previousCursors = [null]
    },
    addCursorToPreviousList(state, cursor) {
      state.previousCursors.push(cursor)
    },
    setCurrentProject(state, project) {
      state.currentProject = project
    },
    setDisciplines(state, disciplines) {
      state.disciplines = disciplines
    },
    setOffices(state, offices) {
      state.offices = offices
    },
    setRegions(state, regions) {
      state.regions = regions
    },
    setLocations(state, locations) {
      state.locations = locations
    },
  },
  actions: {
    logout(context) {
      // Wipe the state
      context.commit("setUser", null)
      context.commit("setServerInfo", null)
      context.commit("setCurrentStream", null)
      context.commit("setCurrentStreamGlobals", null)
      context.commit("setStreams", null)
      context.commit("setProjectStreams", null)
      context.commit("setProjectCollaborators", null)
      context.commit("resetPrevCursors")
      context.commit("setCommits", null)
      context.commit("setCurrentProject", null)
      context.commit("setDisciplines", null)
      context.commit("setOffices", null)
      context.commit("setRegions", null)
      // Wipe the tokens
      speckleLogOut()
    },
    exchangeAccessCode(context, accessCode) {
      // Here, we could save the tokens to the store if necessary.
      return exchangeAccessCode(accessCode)
    },
    async getUser(context) {
      try {
        var json = await getUserAndServerData()
        if (json) {
          var data = json?.data
          context.commit("setUser", data.user)
          context.commit("setServerInfo", data.serverInfo)
          localStorage.setItem('suuid', data.user.suuid)
          localStorage.setItem('uuid', data.user.id)
        }
      } catch (err) {
        console.error(err)
      }
    },
    redirectToAuth() {
      goToSpeckleAuthPage()
    },
    async handleStreamSelection(context, stream) {
      context.commit("setCurrentStream", stream)
      var globalsJson = await getStreamGlobals(stream.id)
      context.commit("setCurrentStreamGlobals", globalsJson.data.stream.globals)
    },
    clearStreamSelection(context) {
      context.commit("setCurrentStream", null)
      context.commit("setCurrentStreamGlobals", null)
    },
    async handleProjectSelection(context, project) {
      try {
        context.commit("setCurrentProject", project)
        var data = await getDisciplines()
        context.commit("setDisciplines", data)
        data = await getOfficesAndRegions()
        context.commit("setOffices", data.offices)
        context.commit("setRegions", data.regions)
        context.commit("setLocations", data.locations)
        context.commit("resetPrevCursors")
      } catch (err) {
        console.error(err)
      }
    },
    clearProjectSelection(context) {
      context.commit("setCurrentProject", null)
    },
    async getUserById(context, id) {
      try {
        var json = await getUserById(id)
        var data = json.data
        if (data) return data.user
      } catch (err) {
        console.error(err)
      }
    },    
    async getCommits(context, cursor) {
      var json = await getStreamCommits(context.state.currentStream.id, 5, cursor)
      context.commit("setCommits", json.data.stream.commits)
    },
    async getStreams(context, cursor) {
      var json = await getStreams(cursor)
      var data = json.data
      context.commit("setStreams", json.data.streams)
      if (data) return data.streams
    },
    async getProjectStreams(context) {
      let globalsStreams = context.state.streams?.items.filter((s) =>
        jobNumberGlobalFilter(s)
      );
      let projectStreams = globalsStreams.filter((s) =>
        matchingJobNumberFilter(
          s.jobNumber, context.state.currentProject.JobCode
        )
      );
      context.commit("setProjectStreams", projectStreams)
      if (projectStreams) return projectStreams
    },   
    async getProjectCollaborators(context) {
      let projectStreams = await this.dispatch("getProjectStreams")
      if (projectStreams) {
        let collaborators = [];
        projectStreams.forEach(async(s) => { 
          let json = await getStreamCollaborators(s.id)  
          var data = json.data.stream.collaborators 
          
          data.forEach(c => { 
            var index = collaborators.findIndex(x => x.id == c.id);           
            if (index === -1) {            
              collaborators.push(c)
            } else {
              if (c.role === "stream:reviewer" && collaborators[index].role !== "stream:reviewer") collaborators[index] = c
            }
          })
        })
        context.commit("setProjectCollaborators", collaborators)
        if (collaborators) return collaborators
      }
    },     
    async createStream(context, streamCreateInput) {
      var json = await createStream(streamCreateInput)
      var data = json.data
      if (data) return data.streamCreate
    },
    async updateStream(context, streamUpdateInput) {
      var json = await updateStream(streamUpdateInput)
      var data = json.data
      if (data) return data.streamUpdate
    },
    async updateStreamJobNumber(context, streamUpdateInput) {
      var json = await updateStreamJobNumber(streamUpdateInput)
      var data = json.data
      if (data) return data.streamUpdate
    },
    async createObject(context, objectInput) {
      var json = await createObject(objectInput)
      var data = json.data
      if (data) return data.objectCreate[0]
    },
    async createCommit(context, commitInput) {
      var json = await createCommit(commitInput)
      var data = json.data
      if (data) return data.commitCreate
    },
    async fetchAdsData(context, state) {
      if (state.useCache) { return }
      var data = await getDisciplines()
      context.commit("setDisciplines", data)
      data = await getOfficesAndRegions()
      context.commit("setOffices", data.offices)
      context.commit("setRegions", data.regions)
      state.useCache = true
    },
    getJob(context, jobNumber) {
      if (jobNumber) {
        return new Promise((resolve, reject) => {
          Vue.prototype.$ads
            .get("Jobs", {
              params: {
                $expand: "Project($select=ScopeOfService,ScopeOfWorks,ProjectDirectorName,ProjectDirectorEmail,ProjectManagerName,ProjectManagerEmail,ProjectUrl)",
                $filter: `startswith(JobCode,'${jobNumber}') and Active eq 1`,
                $select: "JobCode,JobNameShort,JobNameLong,RegionCode",
                $top: 1,
              }
            })
            .then(response => {
              let payload = response.data;
              if (!payload) return reject(null);
              return resolve(payload.value);
            })
            .catch(error => {
              return reject(error);
            });
        });
      }
    },    
    getJobCodesByNumber(context, jobNumber) {
      if (jobNumber) {
        return new Promise((resolve, reject) => {
          Vue.prototype.$ads
            .get("Jobs", {
              params: {
                $expand: "Project($select=ScopeOfService,ScopeOfWorks,ProjectDirectorName,ProjectDirectorEmail,ProjectManagerName,ProjectManagerEmail,ProjectUrl)",
                $filter: `startswith(JobCode,'${jobNumber}') and Active eq 1`,
                $select: "JobCode,JobNameShort,JobNameLong,RegionCode",
                $top: 10,
              }
            })
            .then(response => {
              let payload = response.data;
              if (!payload) return reject(null);
              return resolve(payload.value);
            })
            .catch(error => {
              return reject(error);
            });
        });
      }
    },
    getJobCodesByName(context, jobName) {
      if (jobName) {
        return new Promise((resolve, reject) => {
          Vue.prototype.$ads
            .get("Jobs", {
              params: {
                $expand: "Project($select=ScopeOfService,ScopeOfWorks,ProjectDirectorName,ProjectDirectorEmail,ProjectManagerName,ProjectManagerEmail,ProjectUrl)",
                $filter: `(startswith(JobNameLong,'${jobName}') or contains(JobNameLong,'${jobName}')) and Active eq 1`, 
                $select: "JobCode,JobNameShort,JobNameLong,RegionCode",
                $top: 10,
              }
            })
            .then(response => {
              let payload = response.data;
              if (!payload) return reject(null);
              return resolve(payload.value);
            })
            .catch(error => {
              return reject(error);
            });
        });
      }
    }
  },
  modules: {}
})