import msalAuthHandler from "vue-msal-wrapper";

export const ADSAuth = {
  install(Vue, router, opts = {}) {
    Vue.prototype.$ads = msalAuthHandler.authenticatedApi(
      process.env.VUE_APP_ADS_BASE_URL,
      "ads",
      { "Ocp-Apim-Subscription-Key": process.env.VUE_APP_ADS_SUBSCRIPTION_KEY }
    );
  },
};