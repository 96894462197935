<template>
  <v-container class="pa-0">
    <v-menu offset-y max-height="200px" allow-overflow>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          @keyup="jobNumberChanged(jobNumber)"
          v-model="jobNumber"
          :rules="[validateJobNumber]"
          :label="jobNumberLabel"
          v-bind="attrs"
          v-on="on"
          filled
          rounded
          ref="input-field"
        ></v-text-field>
      </template>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="indigo"
      ></v-progress-linear>
      <div v-if="searchOptions && searchOptions.length > 0">
        <v-list class="overflow-y: auto">
          <v-list-item
            v-for="jobNumberInfo in searchOptions"
            @click="jobNumberSelected(jobNumberInfo)"
            color="grey"
            :key="jobNumberInfo.JobCode"
          >
            <v-list-item-title>{{
              jobNumberInfo.JobCode.concat(" ", jobNumberInfo.JobNameLong)
            }}</v-list-item-title>
            <br />
          </v-list-item>
        </v-list>
      </div>
      <v-list class="overflow-y: auto" v-else-if="searchError">
        <v-list-item color="grey">
          <v-list-item-title>
            <span class="grey--text">
              This doesn't seem to be a valid job number or job name.
            </span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list class="overflow-y: auto" v-else>
        <v-list-item color="grey">
          <v-list-item-title>
            <span class="grey--text">
              Type in a job number (digits only, no spaces or dashes) or job name to search
            </span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
export default {
  name: "JobNumberSearch",
  data() {
    return {
      jobNumberLabel: "Job Number / Job Name",
      searchError: false,
      jobNumberAndName: null,
      jobNumber: null,
      jobName: null,
      projectInfo: null,
      searchOptions: null,
      loading: false,
    };
  },
  methods: {
    //Call back function whenever jobNumber in the text field changes
    jobNumberChanged(jobNumber) {
      this.jobNumber = jobNumber;
      this.jobName = null;

      //The timeout waits for the user to stop typing before running the search
      clearTimeout(this.timeout);
      var self = this;
      this.timeout = setTimeout(function () {
        console.debug("Searching Job numbers with: ", jobNumber);
        self.getJobCodesByNumber(jobNumber);
      }, 600);
    },
    jobNumberSelected(jobObject) {
      this.jobNumber = null;
      var self = this;
      //This tricks the text field to think the jobNumber field updated and reruns the validation
      setTimeout(() => {
        this.jobName = jobObject.JobNameLong;
        this.jobNumber = jobObject.JobCode;
        this.jobNumberAndName = this.jobName + " " + this.jobNumber;
        this.projectInfo = jobObject.Project;

        self.$emit("jobObjectSelected", jobObject);
        this.$store.dispatch("handleProjectSelection", jobObject);
      }, 1);
    },
    validateJobNumber(jobNumber) {
      if (!jobNumber) {
        return "Job number required";
      }

      if (jobNumber == "00000000") {
        return "Do not use this job number";
      }

      if (!this.jobName) {
        return "Invalid job number. Please select correct job number from list.";
      }

      return true;
    },
    async getJobCodesByNumber(jobNumber) {
      let jobNumbers;
      this.loading = true;
      this.searchError = false;
      if (Number.isInteger(Number(jobNumber))) {
        try {
          jobNumbers = await this.$store.dispatch("getJobCodesByNumber", jobNumber);
        } catch (e) {
          this.searchError = true;
          console.error(
            "Failed to get job number: " +
              jobNumber +
              " with error: " +
              e
          );
        }
      } else {
        try {
          jobNumbers = await this.$store.dispatch("getJobCodesByName", jobNumber);
        } catch (e) {
          this.searchError = true;
          console.error(e);
          console.error(
            "Failed to get job number with name: " +
              jobNumber +
              " with error: " +
              e.body
          );
        }
      }

      this.loading = false;
      this.searchOptions = jobNumbers;
    },
  },
};
</script>
