<template lang="html">
  <div v-if="$store.getters.isAuthenticated">
    <v-container v-if="this.$route.params.jobnumber" class="home pa-6">   
      <job-number-search
            ref="input-field"
            @jobObjectSelected="selectedJobNumber"
          ></job-number-search>  
      <project-info-card :project="this.selectedProject"/>        
      <project-streams-card :project="this.selectedProject"/>
      <project-team-card :project="this.selectedProject"/>
    </v-container>
  </div>
</template>

<script>
import JobNumberSearch from "@/components/JobNumberSearch";
import ProjectInfoCard from "@/components/ProjectInfoCard";
import ProjectStreamsCard from "@/components/ProjectStreamsCard";
import ProjectTeamCard from "@/components/ProjectTeamCard";

export default {
  name: "ProjectView",
  components: {
    JobNumberSearch,
    ProjectInfoCard,
    ProjectStreamsCard,
    ProjectTeamCard
  },
  data: () => {
    return {
      loading: false,
      serverUrl: process.env.VUE_APP_SERVER_URL,
      jobNumber: null,
      jobObject: null,
      projectName: null,
    };
  },
  async created(){
    if(!this.$route.params.jobnumber) this.$router.push({ path: '/' })

    let project = await this.$store.dispatch('getJob', this.$route.params.jobnumber)
    await this.$store.dispatch("handleProjectSelection", project[0]);
    this.jobObject = project[0]
  },
  methods: {
    selectedJobNumber(event) {
      this.jobObject = event;
      if (this.jobObject) {
        this.projectName = this.jobObject.JobNameLong;
      }
      this.$emit("jobObjectSelected", event);
      this.$store.dispatch('getStreams', null)
      this.$router.push({ name: 'ProjectView', params: { jobnumber: event.JobCode } })      
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },    
    selectedProject: function () {
      return  this.$store.state.currentProject; 
    },
  },
};
</script>

<style lang="scss">
#viewer {
  min-height: 500px;
}

.v-data-footer__select {
  display: none !important;
}
</style>