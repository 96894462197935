<template lang="html">
  <v-container fill-height class="home flex-column justify-center align-center primary--text mt-6">
    <h1>Welcome to the Speckle@Arup Project Setup App!</h1>
    <h3>This app helps Arup users manage streams across a project.</h3>
    <v-alert type="info" text color="primary" class="my-5 dark">
      Check out the <a href="https://speckle.arup.com/tutorials/project_app.html" target="_blank">the docs</a>!
    </v-alert>
    <p>Please log in to access your Speckle data.</p>
  </v-container>
</template>
<script>
export default {
  name: 'WelcomeView'
}
</script>
