import posthog from 'posthog-js'

// Init PostHog
posthog.init(process.env.VUE_APP_POSTHOG_API_KEY, {
  api_host: 'https://posthog.insights.arup.com',
  autocapture: false,
  capture_pageview: false
})

export function identify(user) {
  posthog.identify(user.id, {
    email: user.email,
    name: user.name,
    app: "project-app"
  })
}

export function capture(event, eventPayload) {
  posthog.capture(event, {
    distinctId: eventPayload.user.id,
    properties: eventPayload
  })
}
